
import { defineComponent, ref } from 'vue'
import ApplicationPop from '@/components/ApplicationPop/index.vue'
import { IComObj } from '@/global/types'
export default defineComponent({
  name: '',
  components: {
    ApplicationPop
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const dialogVisible = ref(false)
    const showDialog = (value: boolean) => {
      dialogVisible.value = value
    }
    const imgs = [
      'header-icon1.png',
      'header-icon2.png',
      'header-icon3.png',
      'header-icon4.png',
      'header-icon5.png'
    ]
    const innerData = ref<IComObj[]>([])

    imgs.forEach((item: string, index: number) => {
      innerData.value.push({
        img: require('@/assets/images/LegalServices/' + item)
      })
    })
    return {
      dialogVisible,
      showDialog,
      innerData
    }
  }
})
