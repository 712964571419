export const allData = {
    intro: [
        {
            name: '公共法律服务门户',
            content:
                '为社会公众、管理对象、四级法律服务中心工作人员提供法律服务、公证服务、法律援助、人民调解、司法鉴定、司法考试、普法宣传、视频会见服务，建设全市统一的“覆盖城乡、业务涵盖、智能便捷、惠及全民”的公共法律服务门户，为不同层次用户提供立体化、全天候、全地域的公共法律服务'
        },
        {
            name: '业务协同综合调度平台',
            content:
                '面向管理对象及内部工作人员，按照“一张网”的业务关联关系，平台统一受理协同办理任务，下达部门调度指令并受理结果，并对整体协同办理过程进行监控。同时在服务过程中，协调复杂疑难问题、预警特定问题、预警处理异常结果事项，最终满足“一号认证、一窗受理、一网通办”的业务办理需要'
        }
    ],
    intro2: [
        {
            name: '运行监督与效能评估系统',
            content:
                '从时间、流程、内容、廉政四类监督指标，配置监督规则，自动采集办件信息，运用大数据技术，比较分析不同级别、部门、事项间数据，对服务工作的整体运行情况从服务时效、服务流程、服务内容及廉政行为等方面进行监督'
        },
        {
            name: '公共法律服务分析研判系统',
            content:
                '采用数据融合技术、互联网技术、空间技术等，构建集信息、产品、分析等多种服务内容为一体的综合性决策支持系统，建立“用数据说话、用数据决策、用数据管理、用数据创新”的管理机制，为决策者、管理者、业务工作人员等不同层次对象提供服务'
        },
        {
            name: '公共法律服务数据资源中心',
            content:
                '对司法行政体系涵盖的所有内外部业务数据进行清理整合，形成司法行政政务信息资源目录，依据目录中涉及的公共法律服务内容，通过公共法律服务网提供“互联网+”式的政务服务、公众服务以及公共数据开放共享业务'
        },
    ],
    features: [
        {
            name: '“四台”融合',
            content:
                '以网络平台为统领，实现跨平台跨项目跨层级整合'
        },
        {
            name: '“三化”便民',
            content:
                '以人民为中心，实现公共法律服务的智能化、便捷化、个性化'
        },
        {
            name: '一站式服务',
            content:
                '以“互联网+”为支撑，实现公共法律服务一站式申办'
        },
        {
            name: '提质增效',
            content:
                '以运行监督为抓手，优化事前事中事后服务效能'
        },
        {
            name: '辅助决策',
            content:
                '以分析研判为载体，提升社会综合治理能力'
        }
    ],
    cases: [
        {
            img: 'LegalServices-case1.png',
            name: '北京市公共法律服务网络平台',
            content:
                '打造一个服务门户，搭建一个调度平台，构建一个数据中心，建设两个支撑系统。综合运用云计算、人工智能和大数据技术，通过门户、APP、微信等渠道，有效整合法制宣传、律师、公证、司法鉴定、法律援助和人民调解等公共法律服务数据资源，向社会提供“法律事务咨询、法律服务指引、法治宣传教育、法律法规与案例查询、信用信息公开”等全流程、全天候、全地域的高效法律服务'
        },
        {
            img: 'LegalServices-case2.png',
            name: '北京市总工会法律服务系统',
            content:
                '本项目主要向北京市总工会职工和市、区、街道乡镇工会提供工会法律援助、劳动争议调解的申请、登记、办理、统计分析以及工会法人资格登记的预约服务。系统包括PC端和小程序端服务，全方位满足工会工作人员、律师和职工的业务办理需求，实现职工法律服务随时、随地，可申请、可预约、可办理、可追踪的业务服务目标'
        }
    ]
}
