import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-79b0caa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "part4-ul" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "left-box" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "right-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: { crossFade: true },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      pagination: {
        clickable: true
      },
      modules: _ctx.modules
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.IData, (item, index) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: require('/public/img/' + item.img),
                    alt: ""
                  }, null, 8 /* PROPS */, _hoisted_4)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h2", null, _toDisplayString(item.name), 1 /* TEXT */),
                  _createElementVNode("p", null, _toDisplayString(item.content), 1 /* TEXT */)
                ])
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modules"])
  ]))
}